import React from 'react'
import {Grid ,GridColumn} from "semantic-ui-react";
import useAuth from '../../hooks/useAuth';
import "./HomeVenta.scss";
import FeedVent from './FeedVent/FeedVent';
import Footer from "./Footer/Footer"


export default function HomeVenta() {
  const auth = useAuth();

  //console.log (auth);


  return (

<>


    <Grid className='homevent' mobile ={16} computer= {16} >

      <Grid.Column centered mobile={16} computer={16} >

       

        <FeedVent/>


    </Grid.Column>

    

        </Grid>

<GridColumn centered mobile= {16}  computer = {16}className='onos' >

<Footer/>
</GridColumn>

</>


  )


}
