import React from 'react'
import { GridColumn, Icon, Grid, Image, Container } from 'semantic-ui-react'
import avat from "../../../asets/png/avat.png"

//import zangano from "../../../asets/png/zangano.png"
//import elzangano from "../../../asets/png/elzangano.jpeg"
//import reina from "../../../asets/png/reina.jpg" 
//import aveja from "../../../asets/png/aveja.jpg"
import gans from "../../../asets/png/gans.png"
import mercado from "../../../asets/png/mercado.png"
import niña from "../../../asets/png/niña.png"
import cielo from "../../../asets/png/cielo.png"
import flor from "../../../asets/png/flor.png"
import ciudad from "../../../asets/png/ciudad.png"
import favela from "../../../asets/png/favela.jpg"
import atizapan from "../../../asets/png/atizapan.jpg"
import pelea from "../../../asets/png/pelea.jpg"
import bramader from "../../../asets/png/bramader.jpg"
import pedro from "../../../asets/png/pedro.jpg"
import cielo4 from "../../../asets/png/cielo4.png"
import cielo2 from "../../../asets/png/cielo 2.png"
import ofertas from "../../../asets/png/ofertas.jpg"


  
import Footer from '../Footer/Footer'

import "./Info.scss"


function Info() {




  return (

    <Grid className='info' centered mobile= {16} >

      <GridColumn computer={3} mobile= {16} className="ima">
        <Image src= {cielo} fluid />
        <h2> E.shop local colaborativa  de establecimeientos y personas basada en el contacto directo por WhatsApp  </h2>
        


      </GridColumn>



      <GridColumn computer={8} centered mobile= {16}>
        <Image src= {pedro}/>
        
        <h2> De personas para personas de tu colonia o localidad.</h2>
        

      </GridColumn>




      
      <GridColumn computer={5} mobile= {16}>
        
        <Image  centered src= {ofertas}/>
        
       
        <h2> Encuentra lo que te gusta, gran variedad de articulos y servicios tecnicos o profesionales de todo tipo a precios accesibles y de promocion.🤩 </h2>
        






      </GridColumn>

      <GridColumn centered computer={16} mobile= {16}>

        <Footer/>
      </GridColumn>













    </Grid>




    
  )
}

export default Info