import React ,{ useState} from "react";
import { Grid, GridColumn , Image, GridRow , Icon, Container, Button  } from "semantic-ui-react"
import { useQuery } from "@apollo/client";
//import { GET_USER } from "../../../gql/user";
//import userAuth from "../../../hooks/useAuth";
import PublicationNot from "../../Publicatex/Main/PublicationNot/PublicationNot"
import ModalBasic from "../../../../components/ModalBasic/ModalBasic";
//import AvatarForm from "../AvatarForm";
//import HeaderProfile from "../HeaderProfile";
//import SettingsForm from "../HeaderProfile/SettingsForm/SettingsForm";
//import UtilForm from "../HeaderProfile/UtilForm/UtilForm";
//import DeleteUser from "../HeaderProfile/UtilForm/Report/DeleteUser";
//import icona from "../../../asets/png/icona.jpg";
//import Folllowers from "./Folllowers";
import ModalFoto from "../ModalFoto/ModalFoto"
import WhatSend from "../../WhatSend";
//import  ActionsVent from "../../../../components/ModalPublicasion /ActionsVent/ActionsVent"
import ActionVent from "../ModalFoto/ActionVenta/ActionVent";
import {Link} from "react-router-dom"
import "./Main.scss";
import { get } from "lodash";
import { GET_PUBLICATION } from "../../../../gql/publication";




export default function Profile (props){
 const { idPublication} = props ;
 const [showModal, setShowModal] = useState(false);
 const [showModalFoto, setShowModalFoto] = useState(false);
 
 //const [publicationSelect, setPublicationSelect ] = useState (null);
 const [titleModal , setTitleModal] = useState("");
 const [childrenModal, setChildrenModal]=  useState(null) ;

 //const {auth} = userAuth ();
 const [wha ,setWha] = useState (null);
 const { data, loading, error, refetch} = useQuery (GET_PUBLICATION,{
  variables: { id:idPublication } ,
});

 

 if (loading) return null ;
 if (error) return <PublicationNot/>;
 const { getPublicate } = data;

 //console.log(idPublication);
 //console.log(data)
 
 const id = idPublication ;
 //console.log(id);
 
 const handlerModal = (type) => {
  switch (type) {
    
       case "Ubicasion" :
       setTitleModal ("Este articulo lo puedes adquirir en ");
        setChildrenModal( <h2>{getPublicate[0].area}</h2>);
        setShowModal(true);
        break; 
        case "Enviar":
          setTitleModal("Manda un mensaje al vendedor ");
          setChildrenModal( <WhatSend publication={getPublicate}   />);
          setShowModal(true);
          break;

          case "precio":
            setTitleModal("Precio publicado ");
            setChildrenModal( <h2>$ {getPublicate[0].precio} MX</h2>
               );
            setShowModal(true);
            break;
  


    default:
      break;
  }

 }


 const openPublication = (publication) => {
  //setPublicationSelect(publication);

  setShowModalFoto(true);

};


 //const liw ='https://api.whatsapp.com/send?phone='+b+'&text=Hola%20me%20interesá%20el%20articulo%20publicado%20en%20la%20colme%20' ;

 //console.log = setWha ;


 return(

    <> 
    <div className="Publication ">
    
    <GridRow className=" Publication-header" centered computer={5}  mobile= {16} height= {1} > <h2>{getPublicate[0].title} </h2></GridRow>
    <Grid >
    <GridRow>

    <GridColumn computer= {5}  mobile = {16}className= "Publication-left">
        <Image src = {getPublicate[0].file}  medium rounded image    onClick={ ()=> openPublication ( )}             />
        
            
    </GridColumn>
     <GridColumn computer ={6}  mobile={16}className = "Publication_center">
     <Container className="starts">
     
   <Icon name= "star" />
   
   <Icon name= "star" />

    <Icon name= "star"/>
    
    </Container>
      <Container textAlign='justified' >
          
     <h2> DESCRIPCION </h2>
          {getPublicate[0].description && (
            <p>{getPublicate[0].description}</p>

          )}
      </Container>
      <ActionVent publication= {id}/>

<Container className="menuicon">
<div>
<Icon name= "map pin"  onClick={()=>handlerModal("Ubicasion")}  />
<span> { getPublicate[0].area}</span>

</div>
<div>
<Icon name= "whatsapp square" onClick={()=>handlerModal("Enviar")} />
<span> Enviar whatsapp </span>
</div>
</Container>
<Container className="menuicon">
<div>
<a href = {`whatsapp://send?text=Hola mira este articulo %20 esta Padre %20 https://lacolme.netlify.app/Publication/${idPublication}&`}   

target= " _ blank"
  rel="noopener noreferrer"
       >

<Icon name= "share square"></Icon>

</a>
<span>Compartir en whats</span>
</div>

<div>
<Icon name= "dollar"  onClick={()=>handlerModal("precio")}
/>
<span> Consulta el precio </span>

</div>

</Container>
  
</GridColumn>

      
</GridRow>
 </Grid>

 <Container fluid className="Publication_stado">

  
  <Icon name="certificate" />
  
  
  <span>{getPublicate[0].estado}</span>
  <Icon name="certificate" />
</Container>


     






     
     <ModalBasic show={showModal} setShow={setShowModal} title = {titleModal}>
     {childrenModal}
      

     </ModalBasic>

     {showModalFoto && (
      <ModalFoto show= {showModalFoto}  setShow= {setShowModalFoto}   getPublicate={getPublicate}  /> )};




     </div>
    
      </>


 );



}

