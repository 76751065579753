import React, {useCallback, useState ,useEffect, useReducer, useRef, } from 'react'
import { useDropzone } from 'react-dropzone';
import { Modal, Icon, Button, Dimmer , Loader, Grid , GridColumn} from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { GET_PUBLICATIONS, PUBLISH } from '../../gql/publication';
import ModalBasic from "../ModalBasic"
import { toast } from 'react-toastify';
import publicContex from '../../context/PublicContex';
import Title from './Title/Title';
//import RegisterForm from '../Auth/RegisterForm/RegisterForm';
//import ModalPubication from '../ModalPublicasion /ModalPubication';
//import Ofert from "./Title/Ofert"
import CompletPublic from "../ModalUpload/Title/CompletPublic"
import "./ModalUpload.scss"




  


function ModalUpload (props) {

const {show , setShow ,}= props;
const [titleModal, setTitleModal] = useState("");
const [showModal , setShowModal] = useState (false);
const [childrenModal, setChildrenModal] = useState (null);

const [fileUpload, setFileUpload] =useState(null);
const [publish ,refetch] =useMutation (PUBLISH);
const [isLoading , setIsLoading] = useState(false);
//const [count, setCount] = useState(null);

const [count, setCount] = useState(null);
const [id , setId]= useState (null);
//const [state, dispatch] = useReducer
 
  
  
  











const handlerModal = (type) => {
  switch (type) {
    case "public":

      
      
     setTitleModal( "Ingresa  los datos para publicar");
      
      setChildrenModal(<CompletPublic  setCount= {setCount}    setShowModal={setShowModal}  />);
      
      setShowModal (true);
    
      break;
      
    default:

      
      break;
  }

 }

 
const onDrop = useCallback((acceptepFile)=> {
  const file = acceptepFile[0];
 
setFileUpload({
    type:"image",
    file,
    preview: URL.createObjectURL(file),

  });


});

const {getRootProps,getInputProps}= useDropzone({
  accept: "image/jpg , image/png",
  noKeyboard:true,
  multiple: false,
  onDrop
})


const onClose = () => {
  setIsLoading(false);
  setFileUpload(null);
  setShow(false);
 //window.location.reload();

  handlerModal ("public");
  
  //window.location= `/Home`;
  


 // window.location.reload();
};

const onPublish = async () => {
  try {
    setIsLoading(true);
    const result = await publish ({
      variables : {
        file: fileUpload.file,
      
      },
    });

    const { data } = result;

   let io = (data.publish.title);

    if (!data.publish.status){

      toast.warning("Error ");

        isLoading(false);
     

        
    } else {
      //console.log (io);
      
      setCount (io)
     
    
       onClose();
      
  

  }
  } catch (error) {
    console.log(error);
    
  }


    

}
  


  return (
   

<publicContex.Provider value = {count}>   

<Modal size= "small" open= {show} onClose = {onClose} className= "modal-up">
     


       

       <div className="dropzone"   {...getRootProps()}style={fileUpload&& {border:0 }} >


          {!fileUpload && (
            <>
          <Icon name='cloud upload' />

          <p>Arrastra tu foto (toca aqui) </p> </>
          )}



          <input {...getInputProps()}/>



          </div>
        {fileUpload?.type === "image" && (
          <div className='image'
          style={{backgroundImage: `url("${fileUpload.preview}")`}}   
          />


        )}
        {fileUpload  && (
          <Button className= 'btn-p btn_acction ' onClick={onPublish}>
            Siguiente ⇛
          </Button>
        )}

        {isLoading && (
          <Dimmer active className="plublishing">
            <Loader/>
            <p> Cargando Imagen ...</p>


          </Dimmer>

       
        )} 
        

        

    </Modal>

    <ModalBasic show={showModal} setShow={setShowModal} title = {titleModal}>
     {childrenModal}     
     </ModalBasic>

    
   
    

    </publicContex.Provider>
   
  )

  
}




export default  ModalUpload


