import React , {useState} from 'react'
import { Icon , Container} from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_LIKE , IS_LIKE, DELETE_LIKE , COUNT_LIKE } from '../../../gql/like';
import "./Actions.scss";

export default function Actions (props) {
    const {publication, lin} = props
    const [addLike]=useMutation(ADD_LIKE);
    const [deleteLike]= useMutation (DELETE_LIKE);
    const [loadingA, setLoadingA] = useState (false);

    console.log (publication)

    



    const {data , loading, refetch} = useQuery(IS_LIKE,{
        variables: {idPublication:publication.id  || publication._id,},

    });


    const { data:data1, loading: loading1 ,refetch: refetch1} = useQuery (COUNT_LIKE,{
        variables: { idPublication: publication.id  || publication._id }
    });


    const onAddLike =  async () =>{
        setLoadingA(true);
        try {
            await addLike({
                variables: {
                    idPublication:publication.id  || publication._id
                },

            });
            refetch ();
            refetch1 ();
    
        } catch (error) {
            console.log (error);
            }
        setLoadingA (false);
        };

        
const onDeleteLike  = async () =>{
    setLoadingA(true);

    try {
        await  deleteLike({
            variables:{
                idPublication:publication.id  || publication._id
            },
        });
        refetch ();
        refetch1 ();
        
    } catch (error) {
        console.log (error);
        
    }
    setLoadingA (false);
   };
    

 
const onAction = () => {
    if (!loadingA) {
        if (isLike){
            onDeleteLike()
        } else {
            onAddLike();
        }
    }

};



if (loading || loading1) return null ;
const {isLike} = data ;
const {countLikes}= data1;
console.log (data1)

//on clicl{isLike ? onDeleteLike : onAddLike}
    

  return (
    <Container className='action' mobile={16} >
        <Icon className={isLike ? "like active" : "like"} name= {isLike ? "heart " : "heart outline"} 
        onClick= {onAction}/>
        
        
        {countLikes} {countLikes=== 1 ? "Like" : "Likes"}


        

    </Container>
  )
};
