import React, {useState , useEffect, useRef} from 'react'

import { Image, Grid, GridColumn, GridRow,Search as SearchSU ,Icon } from 'semantic-ui-react';
import {result, size, map, set} from "lodash"
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import icona from "./../../asets/png/icona.jpg";
import orde from "./../../asets/png/orde.png";
import { SEARCH_PUBLICATION } from '../../../src/gql/publication';


import "./SearchPublic.scss";
import Public from '../../components/Publications/public/Public';
import UserNotFollow from '../../components/Home/UserNotFollow';
import { asyncMap } from '@apollo/client/utilities'; 




export default function SearchPublic() {
  const [search , setSearch] = useState (null);
   const [results, setResults]= useState ([]);
   const [datos, setDatos]= useState([]);
   const inputRef = useRef ({});
   const {data, loading} = useQuery (SEARCH_PUBLICATION, {
      variables:{search}

   });
   
   console.log (results);
   console.log (search);
   console.log(data);


  const onChange = (e) => { if (e.target.value) setSearch (e.target.value);
  else setSearch(null);
}




useEffect(() => {
  if (size(data?.searchPubli ) > 0){
   
    const publics = [];

    console.log("adadaaddadada")

    console.log("logggggggggggg");

    console.log("g");
      
    
    data.searchPubli.forEach((publi) => {
      publics.push({
          

          file:publi.file,
          title:publi.title,
          descrupcion:publi.descripcion,
          
         

       } );
       console.log("ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss",publi.title);

      





       






/*




        for (const key in publi.idUser) {
          if (Object.hasOwnProperty.call(publi.idUser, publi.title)) {
            const element = publi.idUser[publi.title];
            publics.push ({
            name: element.name
  
              
             
                         
          });
            
          }

          
        }

      

*/

    });

    


     setResults (publics);
     
 console.log(publics)
 console.log(data)
 

  }else{
     setResults([]);
     console.log("error")
  }
},[data]);



   
 

    
    
 
 
 
 
 
 
   return (


<>
    <Grid className='buscar' width={10}centered>
      <GridRow></GridRow>
    <GridRow>
      <Image src = {orde} />
      
    </GridRow>
   
   <GridRow>
        
        <h1>   Buscar Peticion  </h1>
      
        </GridRow>
    







     
     <SearchSU  className="searchU"
                fluid
                input={{ icon: "search", iconPosition: "left" }}
                loading = {loading}
                
                onSearchChange = {onChange}
                value = {search || ""}
                results = {results}
                resultRenderer={(e) => < ResultSearch data ={e}   />}
     />





</Grid>

    </>
    





   );

}

 
 
  function ResultSearch (props) {
     const {data , dato} = props;

     console.log ( "datos",data );

     
      return(
        <>

        
    <Image src = {data.file} tiny/>
       
      <div>

    <p> {data.title}  </p>
x



</div>




</>















    )

     
         
      

     
 
 }


 
 
