import React ,  {useEffect, useState}from 'react'
import { Button, Form ,TextArea, Dropdown} from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {ADD_PUBLIC , PUBLIC_COMPLET } from '../../../gql/publication';
import Preview from '../../Publications/PreviewPublication./Preview';
import "./Title.scss";
import usePublic from '../../../hooks/usePublic';



export default function Title (props) {
const  { publication , setShowModal } = props;
const [addPublic] = useMutation (ADD_PUBLIC);
const [completPublic] = useMutation (PUBLIC_COMPLET);

const  id = usePublic();

console.log(id)

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const formik = useFormik ({
  
  initialValues:{
   
    title: "",
    whats:"",
    description:"",
    
    precio:"",
   
    
  },

 

 validationSchema:Yup.object({
    title:Yup.string().required(),
    whats:Yup.string().required()
    .matches(phoneRegExp, 'Whatsapp no valido')
    .min(10, "whats muy corto")
    .max(10, "whats muy largo"),
    description:Yup.string().required(),
    //category:Yup.string().required(),
    precio:Yup.string().required(),



    /*Yup.object()shape({
      decimal: Yup.number().test(
        'is-decimal',
        'invalid decimal',
        value => (value + "").match(/^\d*\.{1}\d*$/),
      ),
    })*/

  }),

  
  onSubmit: async (formv) =>{
     const formData = formv;
      console.log (formData)
    try {
      await completPublic ({
        variables:{
          input:{
            idPublication :id._id,
            title: formData.title,
            whats: formData.whats,
            description: formData.description,
            
            precio: formData.precio,

          },
          },
      });


      //console.log(formik)
     //console.log (formData);




     setShowModal (false);
      //refetch();
      
      //console.log (formData);
    } catch (error) {


      toast.error(error.message);
      console.log (error)

    
    }

    //window.location.reload()
  }

})








  return (
    
        <Form  className='titulo' onSubmit = {formik.handleSubmit}>
        <h2>
          Nombre de la publicasion  
        </h2>
        <Form.Input type= "text" placeholder = "Ingresa el Titulo de la publicasion"
         name= "title" value= {formik.values.title} 
         onChange = {formik.handleChange}
         error={formik.errors.title && true}

    />

        <h2> Whatsapp </h2>
         <Form.Input type= "Nunber" placeholder = "ingresa un Whats de contacto "
         name= "whats"  
         value = {formik.values.whats}
        onChange = {formik.handleChange}
        error={formik.errors.description && true}

      />


         <h2> Descripcion </h2>
         <TextArea  type= "text" placeholder = "ingresa la descripcion de la publicasion "
         name= "description"  
         value = {formik.values.description}
        onChange = {formik.handleChange}
        error={formik.errors.description && true}

      />


 
 

<h2> Precio </h2>
          
<Form.Input type= "text" placeholder = "indica el precio"
           name= "precio" value= {formik.values.precio} 
           onChange = {formik.handleChange}
           error={formik.errors.precio && true}

    />
      <Button type= "submit" className=' btn-submit'>  * Publicar * </Button> 

        </Form>
    
  )
}
function initialValue() {
  return  {
    
    "title": "",
    "whats":"",
    "description":"",
    "category":"",
    "precio":"",
  };
};

const CategoriesOptions = [
  {key:"react",value:"Tecnologia ", text:"Tecnologia"},
  {key:"next", value:"Uso_Personal", text:"Uso Personal"},
  {key:"nec", value:"Hogar", text:"Hogar"},
  {key:"oficina", value:"Oficina", text:"Ofcina"},
  {key:"ali", value:"Alimentos_y_Bebidas", text:"Alimentos y Bebidas"},
  {key:"ser", value:"Servicios", text:"Servicios"},
  {key:"var", value:"Varios", text:"Varios"},
  
];