import React from "react";
import {Link} from "react-router-dom"
import { Form } from "formik";
import UserTotal from "../Report/Usertotal/UserTotal"
import Users from "./Usertotal/Users";
import logo from "./../../asets/png/logo.jpg"
//import zangano from "../../asets/png/zangano.png"
//mport elzangano from "../../asets/png/elzangano.jpeg"
//import reina from "../../asets/png/reina.jpg" 
//import aveja from "../../asets/png/aveja.jpg"
import { Image, Grid, GridColumn, GridRow  } from 'semantic-ui-react';


import "./Report.scss";


 function Report() {





 /* <Grid className='homer' width={5}>
         
       
       <GridColumn >
       <UserTotal/>
       </GridColumn>
     </Grid>
  */
  
  
  return (

        <>
        

     <Grid className='usert' width={5}>
         
       
         <GridColumn >
         <Users/>
         </GridColumn>
       </Grid>




     </>     
   )


}
export default Report