import React from 'react'
import {Modal, Grid ,Image, GridColumn, GridRow, Container } from "semantic-ui-react"
//import ComentForm from '../ModalUpload/ComentForm';
//import Comments from './Comments';
import Actions from "./Actions/Actions"
//import { each, map } from 'lodash';
//import { GET_PUBLICS } from '../../gql/publication';
//import { useQuery } from '@apollo/client';
import ActionsVent from "./ActionsVent/ActionsVent"
import TitlePublic from './titlepublic';
import Public from '../Publications/public/Public';
import Whats from './Whats';
import "./ModalPublication.scss";
import { useState } from "react"





export default function ModalPubication(props) {
    const {show,setShow, publication ,getPublics, publi, id } = props;
    //console.log(getPublics)
    //console.log(publication)

   
const onClose = () => setShow (false);


  return (
    <Modal open={show} onClose={onClose} className="modal_p"  mobile={16}  six wide tablet eight  computer column >

        <Grid six wide tablet eight  computer column  mobile={16} fluid>  
          
            <GridColumn className='modal_p_left' mobile={16}   computer={8} fluid> 
            <Container className = "fondo">
            <h2 centered moblie={16}>  {publication.title} </h2>
            </Container>
                 <Image src = {publication.file} />
                
                 <ActionsVent publication={publication}     />
                  

            </GridColumn>

            

                 

            <GridColumn className='modal_p_right' mobile={16} computer={6}  fluid  > 

            <Container className='descrip' >

             <h2 > Descripción </h2>
             <p> {publication.description}</p>


             </Container>

             <Actions publication={publication}    /> 
             
            
             

            



            </GridColumn>



        </Grid>

        
     
    </Modal>
  
  );
}