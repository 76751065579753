import React from 'react';
import {Form, Button, FormField, Checkbox, FormCheckbox, Icon} from "semantic-ui-react";
import { useFormik , } from "formik";
import * as Yup from "yup" ;
import { toast } from "react-toastify";
import { useMutation } from '@apollo/client/react';
import {REGISTER} from "../../../gql/user";
import Whats from "../../../components/ModalPublicasion /Whats/Whats"

import "../RegisterWatt/RegisterWatt";





export default function RegisterForm(props) {
 const {setShowLogin} = props;
 const [register] = useMutation (REGISTER);
 

 ;

/* <h2 className='register-form-title' mobile={16}>
            Manda un whatsapp para recibir informacion
        </h2>
        <div className=' what'>

        <a href = {`https://api.whatsapp.com/send?phone= 5560587460 &text=Hola%20me%20interesá%20solicitar %20informacion %20 de la %20%20la%20Colme%20`}   

target= " _ blank"
  rel="noopener noreferrer"
       >
 <Icon name='whatsapp'/>
</a>
<div>   <span> Toca el icono de Whatsapp para enviar </span>                                                                                                           </div>

</div>

*/

        







  return (
    <>
       
       <Whats/>
           
           
    </>
  );
}





