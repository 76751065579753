import React ,  {useEffect, useState}from 'react'
import { Button, Form ,TextArea, Dropdown, FormCheckbox} from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {ADD_PUBLIC , PUBLIC_COMPLET } from '../../../gql/publication';
import Preview from '../../Publications/PreviewPublication./Preview';
import "./Title.scss";
import usePublic from '../../../hooks/usePublic';



export default function Title (props) {
const  { publication , setShowModal } = props;
const [addPublic] = useMutation (ADD_PUBLIC);
const [completPublic] = useMutation (PUBLIC_COMPLET);

const  id = usePublic();

console.log(id)

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const formik = useFormik ({
  initialValues:initialValue(),


  validationSchema:Yup.object({
    title:Yup.string().required(),
    whats:Yup.string().required() .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Whatsapp no valido')
    .min(10,"whatsapp muy corto")
    .max(10, "whatsapp muy largo"),
    description:Yup.string().required(),
    //category:Yup.string().required(),
    precio:Yup.string().required(),



    /*Yup.object()shape({
      decimal: Yup.number().test(
        'is-decimal',
        'invalid decimal',
        value => (value + "").match(/^\d*\.{1}\d*$/),
      ),
    })*/

  }),


  onSubmit: async (formv) => {try
    { const formData= formv;
      console.log(formv);

       await completPublic({
        variables:{
          input:{
            idPublication :id._id,
            title: formData.title,
            whats: formData.whats,
            description: formData.description,
            category:formData.category,
            estado :formData.estado ,
            precio: formData.precio,

          },
          },}
      );
      toast.success (" Publicacion en Proceso 😃 ");

      setTimeout(() =>  setShowModal (false),3000);


      
}    catch (error) {
      toast.error(error.message);
      console.log (error)
    }
  },

 });




  return (
    
        <Form  className='titulo' onSubmit = {formik.handleSubmit}>
        <h2>
          Nombre de la publicasion  
        </h2>
        <Form.Input type= "text" placeholder = "Ingresa el Titulo de la publicasion"
         name= "title" value= {formik.values.title} 
         onChange = {formik.handleChange}
         error={formik.errors.title && true}

    />

        <h2> Whatsapp </h2>
         <Form.Input type= "Text" placeholder = "ingresa un Whats de contacto "
         name= "whats"  
         value = {formik.values.whats}
        onChange = {formik.handleChange}
        error={formik.errors.description && true}

      />

<Form.Dropdown className='cat' placeholder= "Categoria"
  options={CategoriesOptions} 
  selection value= {formik.values.category} 
  error={formik.errors.category}
  onChange ={(_,data)=>formik.setFieldValue("category",data.value)}
             
             />

<Form.Dropdown className='cat' placeholder= "Estado del Articulo"
  options={Status} 
  selection value= {formik.values.estado} 
  error={formik.errors.estado}
  onChange ={(_,data)=>formik.setFieldValue("estado",data.value)}
             
             />   


   <h2> Precio </h2>
          
<Form.Input type= "text" placeholder = "indica el precio"
           name= "precio" value= {formik.values.precio} 
           onChange = {formik.handleChange}
           error={formik.errors.precio && true}

    />
                




         <h2> Descripcion </h2>
         <TextArea  type= "text" placeholder = "ingresa la descripcion de la publicasion "
         name= "description"  
         value = {formik.values.description}
        onChange = {formik.handleChange}
        error={formik.errors.description && true}

      />











      <Button type= "submit" className=' btn-submit'>  * Publicar * </Button> 

        </Form>
    
  )
}
function initialValue() {
  return  {
    "idPublication": "",
    "title": "",
    "whats":"",
    "description":"",
    "category":"",
    "estado":"",
    "precio":"",
  };
};

const CategoriesOptions = [
  {key:"react",value:"Tecnologia ", text:"Tecnologia"},
  {key:"next", value:"Uso Personal", text:"Uso Personal"},
  {key:"nec", value:"Hogar", text:"Hogar"},
  {key:"oficina", value:"Oficina", text:"Ofcina"},
  {key:"ali", value:"Alimentos y Bebidas", text:"Alimentos y Bebidas"},
  {key:"ser", value:"Servicios", text:"Servicios"},
  {key:"var", value:"Varios", text:"Varios"},
  
];

const Status = [
  {key:"react",value:"Articulo Nuevo", text:"Articulo Nuevo"},
  {key:"next", value:"Articulo de Uso", text:"Articulo de Uso"},
  {key:"nexti", value:"Servicio", text:"Servicio"},
];