import React , {useEffect, useState}from 'react';
import { Image , Icon, GridRow , Grid} from 'semantic-ui-react';
import {map } from "lodash"
import {Link, Switch} from "react-router-dom"
//import { useQuery } from '@apollo/client';
//import { GET_SAM } from '../../../gql/publication';
import ModalBasic from '../../../../components/ModalBasic/ModalBasic';
import Price from '../../../../components/Publications/public/pricefed/Price';
import TitleEx from "./../../FedExterior/TitleEx/TitleEx"
//import Footer from "../Footer/Footer"


//import WhatSend from '../WhatSend';




import "./FedCategory.scss";

export default function FedCategory(props) {

  const {publication} = props;
  const [showModal, setShowModal] = useState (false);
  const [publicationSelect, setPublicationSelect ] = useState (null);
  //const {data, loading,startPolling,stopPolling }= useQuery (GET_SAM);
  const [titleModal, setTitleModal] = useState ("")
  const [childrenModal, setChildrenModal] = useState (null) 
   





  /*if (loading) return null;
  const {getSample} = data;
  
  
*/

console.log (publication)

  return (

    <>

<div className='feedex' mobile = {16}  computer = {16}>



        {map (publication,(publication, index) => (
          <div key = {index} className = "feedex_box">
            
              <div className='feed_box_user'>
              
                <TitleEx publication={publication} publi ={publication.title} />
               </div>

               <div className='feed_box_precio' mobile={16}>
   
               
            </div>
            <Link to = {`/Publication/${publication._id}`}>
            <div  className='feedex_box_photo' style={{backgroundImage:`url("${publication.file}")`}}
            
            />
            </Link>

   
  

            <div className='feedex_box_ubic'>

              <Icon name = "map marker alternate"/>
               <span>{publication.area}</span>
               
              
            </div>

           

             
            </div>

        ))}
   
    </div>
    
</>
  
  
 ) 
  
  };
