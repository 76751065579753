import {gql} from "@apollo/client"

export const PUBLISH = gql`
 mutation publish ($file: Upload) {
   publish (file: $file) {
    

    
    status
    urlFile
    title{
    _id }
   
    
   
    


    
 }
 } `;

export const ADD_PUBLIC = gql`
 mutation addPublic ($input: PublicInput){
  addPublic (input:$input) {

idPublication
title
whats
description
category
precio


}
}`;






export const GET_PUBLICATIONS = gql`

 query getPublications ($username:String!) {
  getPublications(username:$username) {
    _id
    idUser
    file
    typeFile
    createAt
title
whats
description
precio
count
    
  }
}

`;

export const GET_PUBLICATIONS_FOLLOWEDS = gql`
 query getPublicationsFolloweds {
  getPublicationsFolloweds{
    id,
    idUser{
      name
      username
      avatar
    }
    file
    typeFile
    createAt
  }
  
}

`;

export const GET_PUBLICS = gql`query getPublics ($idPublication: ID!){
  getPublics (idPublication: $idPublication){
  
id
idUser
title
whats
description
precio
count
}
}
`;



export const GET_SEARCH_PUBLICATION = gql` query searchPu ($search:String){
  searchPu (search:$search) {
    
    
    title
     description
    idUser{
      
      username
      area
      
    }
  }
}
`;



export const GET_VENTAS = gql` 
query getPublicationsVent{
  getPublicationsVent{
    
    createAt
    _id
    file
    title
    whats
   description
   precio
   
    idUser{
      id
      name
      username
      avatar
      area
      adminis
    }
    
   
  }
}`;



export const DELETE_PUBLI = gql` 

mutation deletePublication ($idPublication:ID!){
  deletePublication (idPublication:$idPublication)
  
  
  
  
  
}

`;



export const GET_SAM = gql` 
query getSample { getSample{
  
  createAt
    _id
    title
    file
   area

     
}
  
}


`;




export const PUBLIC_COMPLET = gql` 
mutation completPublic($input:CompletPublicationInput)
{completPublic(input:$input)}



`;



export const SEARCH_PUBLICATION = gql`

query searchPubli ($search: String ) {
  searchPubli (search:$search) {
    _id
    file
    title
    description
    precio
    area
    whats
    
  }
}



`;


export const GET_PUBLICATION = gql`
query getPublicate ($id: ID!){
  getPublicate (id: $id){
  
    
    title
    file
    area
    description
    createAt
    precio
    whats
    estado
    ofertPre
    category
  }
    
}

`;

export const GET_CATEGORY = gql`
query getPublicationsCatego ($category: String! ){
  getPublicationsCatego(category:$category){
    
    createAt
    _id
    file
    title
   
   precio
   area
  category
  
    
  }
    
}

`;

