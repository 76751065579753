import React from 'react';
import {Container,Grid, Image , Icon,GridColumn, GridRow, Divider ,Button , Menu} from "semantic-ui-react";
import {Link} from "react-router-dom"
import despegar300 from "../../../asets/png/despegar300 .png"
import fly300 from "../../../asets/png/fly300.png"

import "./HeaderEx.scss"; 

export default function Header() {
  return (

   <>
    
    <Grid   className='headEx' mobile= {16} computer= {16}>

    
     
    
   

  
  <div centered className='centre'>
  <Link  to = "/">
      <h3>La Colme</h3>
      </Link>
    
      </div>  

      
<div className='dere'>
<Button  ui inverted green basic  >
<Link  to = "/Bienvenida">
<Image src = {despegar300} verticalAlign='boottom'/>
<span>Iniciar</span>
</Link>
</Button>
</div> 




 </Grid>
 
   </>
  )
}
