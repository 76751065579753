import React from 'react';
import { Button, Container, Form , FormCheckbox, Icon} from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useMutation } from '@apollo/client';
//import { useQuery } from '@apollo/client';
//import { GET_PUBLICS } from '../../../gql/publication';
import { ADD_COMMENT } from '../../../gql/comment';
import "./Whats.scss";

export default function ComentForm(props) {
  const {publication }= props;


/*

  const {data, loading}= useQuery(GET_PUBLICS,{
    variables: {
      idPublication: publication.id  || publication._id,
    }
  
  });
  
  if (loading) return null;
  
  const {getPublics} = data;
  
  //console.log (data);
  
  //console.log(publication.username)

  const aviso = () => {
    
    alert("no has iniciado sesion ")

};

  
  
   const onSubmit = () => 
      
  <a href = {`https://api.whatsapp.com/send?phone=${publication.whats}&text=Hola%20me%20interesá%20el%20articulo%20publicado%20${getPublics[0].title}%20la%20Colme%20`}   

target= " _ blank"
  rel="noopener noreferrer"
       >
 
</a>
        
        */
  
    



  
  return (

    <>
    <Container className='envia'>
     <h2>Envia un whatsapp</h2>
     </Container>
    <Container className='coment-form'>

      <h3> Hola me interesa solicitar informacion de la colme para Registrarme </h3>
      
      
      <Icon name ="paper plane" /> 
    


<Button type= "submit"> <a href = {`https://api.whatsapp.com/send?phone= 5560587460 &text=Hola%20me%20interesá%20solicitar %20informacion %20 de la %20Colme%20`}  

target= " _ blank"
  rel="noopener noreferrer"
       > <span> Enviar .  </span>
 <Icon name='whatsapp'/>
</a> </Button>

    
  </Container>



</>

  )
}
