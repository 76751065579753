import React from 'react'
import {Modal, Grid ,Image, GridColumn, GridRow, Container } from "semantic-ui-react"
//import ComentForm from '../ModalUpload/ComentForm';
//import Comments from './Comments';
//import Actions from "./Actions/Actions"
import { each, map } from 'lodash';
//import { GET_PUBLICS } from '../../gql/publication';
//mport { useQuery } from '@apollo/client';
//import ActionsVent from "./ActionsVent/ActionsVent"
//import TitlePublic from './titlepublic';
import "./ModalFoto.scss";
import { useState } from "react"





export default function ModalFoto(props) {
    const {show,setShow, getPublicate,tublic ,getPublics, } = props;
    //console.log(getPublics)

    console.log(getPublicate);
    console.log(getPublicate[0].description)

   
const onClose = () => setShow (false);


  return (
    <Modal open={show} onClose={onClose} className="modal_foto" computer={8}   c>

        <Grid six wide  mobile={16}     >  
          

                  
          <Image src = {getPublicate[0].file} />
                
                
      
        </Grid>

        
     
    </Modal>
  
  );
}
