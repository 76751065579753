// Layouts
import LayoutBasic from "../layouts/LayoutBasic";
import LayExterior from "../pages/Homeventas/HeaderEx";

// Pages
import HomeVenta from "../pages/Homeventas";
import User from "../pages/User";
import Error404 from "../pages/Error404";
import Bienvenida from "../pages/Bienvenida/Bienvenida";
import Report from  "../pages/Report/Report";
import SearchPublic from "../pages/SearchPublic/SearchPublic";
import SerachUser from "../pages/SerachUser/SerachUser";
import Registre from "../pages/Regis/Registre";
import Preview from "../pages/Preview/Preview";
import Catego from "../pages/Homeventas/Category/Category"
import HomeCategory from "../pages/Homeventas/Category/HomeCategory/HomeCategory";
import BuscarEx from "../pages/Homeventas/BuscarEx/BuscarEx";
import info from "../pages/Homeventas/Info/Info"

//import Inicio  from "../pages/Homeventas/Inicio/Inicio";




const routes = [
  {
    path: "/Bienvenida",
    layout: LayoutBasic,
    component: Bienvenida,
    exact: true,
  },
  {
    path: "/",
    layout: LayoutBasic,
    component: Bienvenida,
    exact: true,
  },
  

  {
    path: "/Category",
    layout: LayoutBasic,
    component:Catego,
    exact: true,
  },

  {
    path: "/HomeVenta",
    layout: LayoutBasic,
    component: HomeVenta,
    exact: true,
  },
  {
    path: "/SearchPublic",
    layout: LayoutBasic,
    component: SearchPublic,
    exact: true,
  },
  
  {
    path: "/Registre",
    layout: LayoutBasic,
    component: Registre,
    exact: true,
  },

{
    path: "/info",
    layout: LayoutBasic,
    component: info,
    exact: true,
  },

  
  {
    path: "/Rep",
    layout: LayoutBasic,
    component: Report,
    exact: true,
  },


  {
    path: "/BuscarUser",
    layout: LayoutBasic,
    component:SerachUser,
    exact: true,
  },

  {
    path: "/:username",
    layout: LayoutBasic,
    component: User,
    exact: true,
  },

  {
    path: "/Publication/:idPublication",
    layout: LayoutBasic,
    component:Preview,
    exact: true,
  },


  {
    path: "/Category/:catego",
    layout: LayoutBasic,
    component: HomeCategory,
    exact: true,
  },


  
  {
    layout: LayoutBasic,
    component: Error404,
  },

 


];

export default routes;