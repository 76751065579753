import React from 'react';
import {Modal,ModalContent, Header,  ModalActions,  ModalHeader, ModalDescription} from "semantic-ui-react";
import "./ModalBasic.scss";


export default function ModalBasic(props) {
  const {show ,setShow, title, children, auth} = props;

  const onClose = () => {
    setShow (false);

  }


  return (
    <Modal size= "mini" open = {show} onClose = {onClose} className= "modal-basic" >

    <ModalDescription >
    {title}
    </ModalDescription>


   <ModalContent >
        {children}
    </ModalContent>
                                                                                
    </Modal>



  )
}
