import React , {useState} from 'react'
import { Container, Icon ,Grid, GridRow,  } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import {COUNT_SOLD} from "../../../../../gql/sold"

import "./ActionVent.scss";





export default function Action(props) {
    const {publication  } = props
    

  
    const { data, loading, refetch } = useQuery ( COUNT_SOLD,{
        variables: { idPublication : publication }

        
    });
   


   if(loading) return null;

   const  {countSolds}= data ;


  return (

    <>

    <Container fluid  className = { countSolds ? "vendid" : "disponi"}>

    <span>
     <Icon className= {countSolds ? "sold.active" : "sold"} name= { countSolds? "stopwatch" : "check circle "}  />

        
{ countSolds ? "Agotado" : "Disponible"}
       
    </span>


    </Container>

    
         

  

    </>
  )
};
