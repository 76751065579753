import React from 'react'
import {Form , Button } from "semantic-ui-react";
import { useFormik } from 'formik';
import * as Yup from "yup" ;
import { useMutation } from '@apollo/client';

import { toast } from 'react-toastify';

import "./ComfirmDeletePublic.scss" ;
import { DELETE_PUBLI } from '../../../../gql/publication';

 export default function ComfirmDeletePublic (props) {
    const {setShowModal, currentTitle , publication, refetch } = props ;
    const[ deletePublication ] = useMutation(DELETE_PUBLI);

   
    
    
      const formik = useFormik({
        initialValues : {
          idPublication: "", 
        },
        validationSchema: Yup.object ({
          username: Yup.string().required()
          .oneOf([Yup.ref("repeatUsername")]),
          repeatUsername: Yup.string().required()
              .oneOf([Yup.ref("username")])
          
        }),
        onSubmit: async(FormData) => {
          try{
            await deletePublication({
                variables: {
                    idPublication:publication._id
                },

                
            }
            );
            
            refetch();
            setShowModal(false);
          } catch (error) { 
            
            setShowModal(false);
            toast.error(" Publicacion eliminada ")
            
          }
  
        }   
  
      })
  
   
    

  return (

    <Form className='confirm-form' onSubmit={formik.handleSubmit}>
        
        
        <Form.Input 
         placeholder = {currentTitle}name= "username" value={formik.values.username} onChange={formik.handleChange}
        error={formik.errors.username && true}
        />
        <Form.Input 
         placeholder = {currentTitle} name= "repeatUsername" value={formik.values.repeatUsername} onChange={formik.handleChange}
        error={formik.errors.repeatUsername && true}
        />


        <Button type = "submit" className='btn-remove' >Borrar</Button>

    </Form>
    
    
  );

}


