import React from 'react';
import { useParams } from 'react-router-dom';
//import { size } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_PUBLICATION, } from '../../gql/publication';
import Main from '../Homeventas/Publicatex/Main/Main';
import "./Preview.scss"



export default function Preview () {
 const {idPublication } = useParams () ;
 
  const {data, loading}= useQuery(GET_PUBLICATION,{
    variables: { id:idPublication } ,
  });

  if (loading) return null;

  const {getPublicate} = data;
 
 console.log (useParams);
 console.log (getPublicate)
 console.log (idPublication)

  
  return (
    <>
       
    <Main idPublication={idPublication} getPublicate={getPublicate}/>

    </>
  );
}
