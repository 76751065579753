import React, {useState , useEffect, useRef} from 'react'

import { Image, Grid, GridColumn, GridRow,Search as SearchSU ,Icon, Container } from 'semantic-ui-react';
import {result, size, map, set} from "lodash"
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
//import icona from "./../../../asets/png/icona.jpg";

import { SEARCH_PUBLICATION } from '../../../gql/publication';
import ModalBasic from '../../ModalBasic';
//import WhatSend from '../WhatSend';
//import buscar from "../../../asets/png/buscar.png"


import "./SearchP.scss";



export default function BuscarEx() {
  const [search , setSearch] = useState (null);
   const [results, setResults]= useState ([]);
   const [datos, setDatos]= useState([]);
   
   const inputRef = useRef ({});
   const {data, loading} = useQuery (SEARCH_PUBLICATION, {
      variables:{search}

   });
   
   

  const onChange = (e) => { if (e.target.value) setSearch (e.target.value);
  else setSearch(null);
}




useEffect(() => {
  if (size(data?.searchPubli ) > 0){
   
    const publics = [];

   
    
    data.searchPubli.forEach((publi) => {
      publics.push({
          

          file:publi.file,
          title:publi.title,
          descripcion:publi.description,
          area:publi.area,
          
          ident:publi._id,
          
         

       } );
      



    });

    


     setResults (publics);
     
 

  }else{
     setResults([]);
     console.log("error")
  }
},[data]);

 
 
 
 
   return (


<>
  
      
  
     
     <SearchSU  className="search-users "
                fluid
                input={{ icon: "search", iconPosition: "left" }}
                loading = {loading}
                
                onSearchChange = {onChange}
                value = {search || ""}
                results = {results}
                resultRenderer={(e) => < ResultSearch data ={e}   />}
                mobile = {16}
                centered 
     />

    

    </>
   );

}

 
 
  function ResultSearch (props) {
    const [showModal, setShowModal] = useState (false);
    const [titleModal, setTitleModal] = useState ("")
    const [publicationSelect, setPublicationSelect ] = useState (null);
   const [childrenModal, setChildrenModal] = useState (null) 
   
     const {data , dato} = props;

     console.log ( "datos",data );

     
      return(
        <div  className='republics'>

    <Link to = {`/Publication/${data.ident}`}>   
    <Image  src = {data.file} />
    </Link>

    <Link to = {`/Publication/${data.ident}`}>   
    <p> {data.title}  </p>

    <p> {data.descripcion}  </p>

    </Link>
    
    


    <span> <Icon name = "map marker alternate"/> {data.area}  </span>


</div>

) }


 
 
