import React , {useState} from 'react'
import { Container, Icon ,Grid, GridRow,  } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PUBLICS } from '../../../gql/publication';
import { ADD_LIKE , IS_LIKE, DELETE_LIKE , COUNT_LIKE } from '../../../gql/like';
import publicContex from '../../../hooks/usePublic';
import useAuth from '../../../hooks/useAuth';
import {ADD_SOLD, IS_SOLD ,COUNT_SOLD} from "../../../gql/sold"
import { get } from 'lodash';
import "./ActionsVent.scss";





export default function Actions(props) {
    const {publication ,getPublics , tublic } = props
    const [addSold]=useMutation(ADD_SOLD);
    //const [deleteLike]= useMutation (DELETE_LIKE);
    const [loadingA, setLoadingA] = useState (false);
   
   // const [setPublit , publit]= useState([]);
    const auth =useAuth();
   
    console.log (auth);

    

    //console.log (auth.auth.id)
   // console.log (publication.idUser.id);


    


   
    //console.log( publication.id)
    console.log (publication , "holasa");

   //console.log(getPublics[0].id)
   //console.log(tublic.id);

















   



    const {data , loading, refetch} = useQuery(IS_SOLD,{
        variables: { idPublication : publication.id  || publication._id || publication},

    });


    const { data:data1, loading:loading1, refetch:refetch1} = useQuery ( COUNT_SOLD,{
        variables: { idPublication : publication.id  || publication._id || publication}
    });

    console.log(data)
    

    const onAddSold =  async () =>{
        setLoadingA(true);
        try {
            await addSold({
                variables: {
                    idPublication:publication.id  || publication._id || publication
                },

                
            });
            refetch ();
            refetch1 ();
           
    
        } catch (error) {
            console.log (error);
            }
          setLoadingA (false);
        };

        /*
const onDeleteLike  = async () =>{
    setLoadingA(true);

    try {
        await  deleteLike({
            variables:{
                idPublication:publication.id
            },
        });
        refetch ();
        refetch1 ();
        
    } catch (error) {
        console.log (error);
        
    }
    setLoadingA (false);
   };
    

 */
const onAction = () => {
    if (!loadingA) {
        if (isSold){
            alert("ya se marco como vendido")
            
        } else { onAddSold();
        }
    }

};



if (loading || loading1) return null ;
const {isSold} = data;

console.log (data1)
const {countSolds } = data1;

//onclicl{isLike ? onDeleteLike : onAddLike}
    

  return (

    <>

    <Grid.Row fluid  mobile = {16}className = { countSolds ? "vendido" : "disponible"}>

    <span>
     {publication.idUser === auth.auth.id ? ( <Icon className= {countSolds ? "sold.active" : "sold"} name= { countSolds? "check circle" : "check circle outline"}
      
 onClick= {onAction}   />


 

      
):(   <Icon name= "dollar sign" /> )}



        
{ countSolds ? "Vendido" : "Disponible"}
       
    </span>


    </Grid.Row>

    <Grid.Row fluid className = { countSolds  ? "vendido_spam" : "disponible_spam"}>


    <div className='ac'>

    {publication.idUser  === auth.auth.id  ? (<span> { countSolds ? "Sin stock" : "Marcar como  Agotado  "} </span>
        
        
        


  ):(<span> { countSolds ? "### Agotado ###" : "*** Asequible *** "}  </span>)}
 
        
 
 </div>
        
        
 </Grid.Row>
         

  

    </>
  )
};
