import React from 'react'
import { GridColumn, Icon, Grid, Image, Button ,Segment, GridRow } from 'semantic-ui-react'
import { Link } from 'react-router-dom'



import varios from "../../../asets/png/varios.jpg"
import servicios from "../../../asets/png/servicios.jpg" 
import oficina from "../../../asets/png/oficina .png"
import gans from "../../../asets/png/gans.png"
import hogar from "../../../asets/png/hogar.png"
import boutique from "../../../asets/png/boutique.jpg"
import ofertas from "../../../asets/png/ofertas.jpg"
import alimentos from "../../../asets/png/alimentos.png"
import electrónica from "../../../asets/png/Electronica.png"
import Footer from '../Footer/Footer'



import "./Category.scss"


//const [category, setCategory] = useState (null) ;



function Category() {




  return (

    <Grid className='catego'>

      <GridRow    computer={16} mobile= {16} >
        <Image circular centered src= {gans}   />
        
      


      </GridRow>
      <GridColumn  computer={16} mobile= {16}>
      <h2> Encuentra lo que te gusta 🤩 </h2> 
      </GridColumn>
    
        
      <GridColumn width={8}  mobile={8} >
          <Segment attached='top' className= "boton"   >
            <span >Artículos de electrónica, gadgets, telefonía, computo, videojuegos y accesorios.</span>
            <Image centered src= {electrónica}/>
          </Segment>
          <Link to = "/category/Tecnologia">
          <Button attached='top' content='Tecnología' /> 
        </Link>
<Segment attached='top'className= "boton">
            <span> Artículos para el hogar, jarcería, jardinería, cocina, accesorios para mascotas.etc.etc       </span>

            <Image centered src= {hogar}/>
          </Segment>
          <Link to = "/Category/Hogar">
          <Button attached='top' content='Hogar' Link to = "/Hogar"/>
          </Link>
<Segment attached='top'className= "boton">
            <span>Encuentra Alimentos y bebidas de establecimientos en tu comunidad y solicita el servicio por WhatsApp.     </span>
            <Image centered src= {alimentos}/>
          </Segment>
          <Link to = "/Category/Alimentos y Bebidas" >
          <Button attached='top' content='Alimentos y bebidas'/>
          </Link>

          <Segment attached='top'className= "boton">
            <span> Encuentra artículos de oportunidad a precios de oferta o liquidación.</span>
            <Image centered src= {ofertas}/>
          </Segment>
          <Link to = "/Category/Ofertas" >
          <Button attached='top' content='Ofertas' />
          </Link>



        </GridColumn>

   
        <GridColumn width={8}  mobile={8}>
          <Segment attached='top'className= "boton">
            <span>Artículos de boutique, ropa, calzado, belleza, cuidado personal, fragancias y bisutería.</span>
            <Image centered src= {boutique}/>
          </Segment>
          <Link to = "/Category/Uso Personal" >
          <Button attached='top' content=' Uso Personal' />
          </Link>
<Segment attached='top'className= "boton">
            <span>Artículos de oficina, papelería, equipo de cómputo, impresión, accesorios varios. etc .etc </span>
            <Image centered src= {oficina}/>

          </Segment>
          <Link to = "/Category/Oficina" >
          <Button attached='top' content='Oficina' />
          </Link>




<Segment attached='top'className= "boton">
           <span>Encuentra servicios de personal técnico y profesional cerca de tu localidad. </span>
           <Image centered src= {servicios}/>
          </Segment>
          <Link to = "/Category/Servicios" >
          <Button attached='top' content='Servicios' />
          </Link>

          <Segment attached='top'className= "boton">
            <span>Varios encuentra artículos de la más amplia variedad de opciones para elegir. </span>
            <Image centered src= {varios}/>
          </Segment>
          <Link to = "/Category/Varios" >
          <Button attached='top' content='Varios' />
          </Link>



        </GridColumn>



        


        

   
      

      <GridColumn centered computer={16} mobile= {16}>

        <Footer/>
      </GridColumn>













    </Grid>




    
  )
}

export default Category