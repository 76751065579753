import React from 'react';
import { useQuery } from '@apollo/client';
import { map } from 'lodash';
import {GET_PUBLICS} from "../../../../gql/publication";
import { Icon , Container} from 'semantic-ui-react';

import "./TitleEx.scss";




function Public(props) {
 const {publication , publi} = props;


 //console.log (publication)
 //console.log (publi)


/*const {data, loading}= useQuery(GET_PUBLICS,{
  variables: {
    idPublication: publication._id,
  }

});

if (loading) return null;

const {getPublics} = data;

//console.log (data);*/





  


  return (

   
    
    <div>

     
        
        
               
          
                <Container className='tiex'>

                
                 <h1>{publi}</h1>
                 <Icon name = "whatsapp"/>
                  </Container>
                  

                 
                </div>

                


                



        


   
  )
}



 

export default Public