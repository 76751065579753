import React from 'react'
import { Icon, GridRow , Grid, Container ,Menu, MenuItem, GridColumn } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import "./Footer.scss"



//const history = ();

const aviso = () => {
    
  window.history.go(-1);

};

const reload = () => {
    

  window.location.reload()
};



export default function footer() {
  return (



<>
<GridColumn centered mobile = {16} className='onos'  >
  
 
<Icon name= "arrow alternate circle left" onClick={aviso} />



<Link to = "/Category">
<Icon name= "sitemap"  />

</Link>





<a href="whatsapp://send?text=  Hola mira esta shop local colaborativa  basada en el contacto directo por WhatsApp %20 https://lacolme.netlify.app/">
<Icon name= "share square"></Icon>
</a>




<Link  to = "/info" > 
<Icon name= "question circle outline"/>
</Link>






<Icon name= "redo"  onClick={reload} />


</GridColumn>

</>

  )
}
