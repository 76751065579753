import React from 'react'
import { GridColumn, Icon, Grid, Image } from 'semantic-ui-react'
import avat from "../../../asets/png/avat.png"

//import zangano from "../../../asets/png/zangano.png"
//import elzangano from "../../../asets/png/elzangano.jpeg"
//import reina from "../../../asets/png/reina.jpg" 
//import aveja from "../../../asets/png/aveja.jpg"
import gans from "../../../asets/png/gans.png"
import mercado from "../../../asets/png/mercado.png"
import niña from "../../../asets/png/niña.png"
import cielo from "../../../asets/png/cielo.png"
import flor from "../../../asets/png/flor.png"
import ciudad from "../../../asets/png/ciudad.png"
//import gans from "../../../asets/png/gans.png"


  
import Footer from '../Footer/Footer'

import "./TM.scss"


function Tm() {




  return (

    <Grid className='tm'>

      <GridColumn computer={3} mobile= {16} className="ima">
        <Image src= {gans} fluid />
        <h2> It Works! </h2>
        


      </GridColumn>



      

      <GridColumn centered computer={16} mobile= {16}>

        <Footer/>
      </GridColumn>













    </Grid>




    
  )
}

export default Tm