import React , {useEffect, useState}from 'react';
import { GridRow, Image , Icon, Container , Loader ,Dimmer} from 'semantic-ui-react';
import {map } from "lodash"
import {Link, Switch} from "react-router-dom"
import { useQuery } from '@apollo/client';
import { GET_VENTAS , GET_PUBLICS} from '../../../gql/publication';
import Actions from '../../../components/ModalPublicasion /Actions';
import ActionsVent from "../../../components/ModalPublicasion /ActionsVent"
import ModalPubication from '../../../components/ModalPublicasion /ModalPubication';
import icona from "../../../../src/asets/png/icona.jpg";
import Public from '../../../components/Publications/public';
import Price from '../../../components/Publications/public/pricefed';
import TitlePublic from '../../../components/ModalPublicasion /titlepublic/TitlePublic';
import TitleEx from "./../FedExterior/TitleEx/TitleEx"

import "./FeedVent.scss";

export default function FeedVent(props) {

  const {publication} = props;
  const [showModal, setShowModal] = useState (false);
  const [publicationSelect, setPublicationSelect ] = useState (null);
  const {data, loading, startPolling, stopPolling }= useQuery (GET_VENTAS);
  //const {data1 , loading0}= useQuery (GET_PUBLICS)
  //const {pub , setPub} = useState (null);
  

  

/*useEffect(() => {
  startPolling(2000)
  return () => {
   stopPolling();
  }
}, [startPolling,stopPolling])*/

  if(loading) return null;
  const {getPublicationsVent} = data;
  
  
  const openPublication = (publication) => {
    setPublicationSelect(publication);

    setShowModal(true);

  };


  return (

    <>

<div className='feed' mobile = {16}  computer = {12}>
  
        {map (getPublicationsVent,(publication, index) => (
          
          <div key = {index} className = "feed_box" mobile={16}>
  

            <Link to = {`/${publication.idUser.username}`}>
              <div className='feed_box_user' mobile={16}>
                <Image src= {publication.idUser.avatar  || icona } avatar />
                <span>{publication.idUser.name}</span>
                <p>{( new Date(publication.createAt*1)).toLocaleDateString() }</p>  
                </div>
                <div className='feed_box_user' mobile={16}>
                
              <TitlePublic publication={publication}/>
      </div>    
    </Link>
    <Link to = {`/Publication/${publication._id}`}>
            <Image className='feed_box_photo'mobile={16}
             src= {publication.file} 
            onClick={ ()=> openPublication (publication )}
            
            />

</Link>
            <div className='feed_box_action'>

            
            
            
            </div>

            <GridRow centered className='feed_box_user'   mobile={16}>
<GridRow centered className='price'>

 <h2 > <Icon name = "dollar"/>{publication.precio}</h2>

 
  

 </GridRow>
 
</GridRow>

           

              
              
          </div>

        ))}
    </div>

    







    {showModal && (
      <ModalPubication  show= {showModal}  setShow= {setShowModal}    publication={publicationSelect}    /> )};



















    
</>
  
  
 ) 
  
  };
