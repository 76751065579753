import React from 'react'
import {Grid ,GridColumn} from "semantic-ui-react";
//import useAuth from '../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {GET_CATEGORY} from "../../../../gql/publication"
import "./HomeCategory.scss";
import FeedCategory from '../FedCategory/FedCategory';
import Footer from '../../Footer/Footer';

export default function HomeVenta() {
  //const auth = useAuth();
  //const {category} = props ;
  const {catego} = useParams () ;
 
  //console.log (useParams);
  const {data, loading}= useQuery(GET_CATEGORY,{
    variables: { category:catego } ,
  });

  if (loading) return null;

  const {getPublicationsCatego} = data;

  
  return (

<>


    <Grid className='homecate' mobile ={16} computer= {16} >

      <Grid.Column centered mobile={16} computer={16} >


        <FeedCategory  publication = {getPublicationsCatego}/>

       

        
    </Grid.Column>

    
</Grid>
<GridColumn centered mobile= {16}  computer = {16}className='onos' >

        <Footer/>
    </GridColumn>




</>

  )


}
